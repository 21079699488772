.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

.rightBoxShadow {
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1)
}