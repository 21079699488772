:root {
    --unit: 4px;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: var(--unit) !important;
}
.mt-2 {
  margin-top: calc(var(--unit) * 2) !important;
}
.mt-3 {
  margin-top: calc(var(--unit) * 3) !important;
}
.mt-4 {
  margin-top: calc(var(--unit) * 4) !important;
}
.mt-5 {
  margin-top: calc(var(--unit) * 5) !important;
}
.mt-6 {
  margin-top: calc(var(--unit) * 6) !important;
}
.mt-7 {
  margin-top: calc(var(--unit) * 7) !important;
}
.mt-8 {
  margin-top: calc(var(--unit) * 8) !important;
}
.mt-9 {
  margin-top: calc(var(--unit) * 9) !important;
}
.mt-10 {
  margin-top: calc(var(--unit) * 10) !important;
}
.mt-11 {
  margin-top: calc(var(--unit) * 11) !important;
}
.mt-12 {
  margin-top: calc(var(--unit) * 12) !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: var(--unit) !important;
}
.ml-2 {
  margin-left: calc(var(--unit) * 2) !important;
}
.ml-3 {
  margin-left: calc(var(--unit) * 3) !important;
}
.ml-4 {
  margin-left: calc(var(--unit) * 4) !important;
}
.ml-5 {
  margin-left: calc(var(--unit) * 5) !important;
}
.ml-6 {
  margin-left: calc(var(--unit) * 6) !important;
}
.ml-7 {
  margin-left: calc(var(--unit) * 7) !important;
}
.ml-8 {
  margin-left: calc(var(--unit) * 8) !important;
}
.ml-9 {
  margin-left: calc(var(--unit) * 9) !important;
}
.ml-10 {
  margin-left: calc(var(--unit) * 10) !important;
}
.ml-11 {
  margin-left: calc(var(--unit) * 11) !important;
}
.ml-12 {
  margin-left: calc(var(--unit) * 12) !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: var(--unit) !important;
}
.mb-2 {
  margin-bottom: calc(var(--unit) * 2) !important;
}
.mb-3 {
  margin-bottom: calc(var(--unit) * 3) !important;
}
.mb-4 {
  margin-bottom: calc(var(--unit) * 4) !important;
}
.mb-5 {
  margin-bottom: calc(var(--unit) * 5) !important;
}
.mb-6 {
  margin-bottom: calc(var(--unit) * 6) !important;
}
.mb-7 {
  margin-bottom: calc(var(--unit) * 7) !important;
}
.mb-8 {
  margin-bottom: calc(var(--unit) * 8) !important;
}
.mb-9 {
  margin-bottom: calc(var(--unit) * 9) !important;
}
.mb-10 {
  margin-bottom: calc(var(--unit) * 10) !important;
}
.mb-11 {
  margin-bottom: calc(var(--unit) * 11) !important;
}
.mb-12 {
  margin-bottom: calc(var(--unit) * 12) !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: var(--unit) !important;
}
.mr-2 {
  margin-right: calc(var(--unit) * 2) !important;
}
.mr-3 {
  margin-right: calc(var(--unit) * 3) !important;
}
.mr-4 {
  margin-right: calc(var(--unit) * 4) !important;
}
.mr-5 {
  margin-right: calc(var(--unit) * 5) !important;
}
.mr-6 {
  margin-right: calc(var(--unit) * 6) !important;
}
.mr-7 {
  margin-right: calc(var(--unit) * 7) !important;
}
.mr-8 {
  margin-right: calc(var(--unit) * 8) !important;
}
.mr-9 {
  margin-right: calc(var(--unit) * 9) !important;
}
.mr-10 {
  margin-right: calc(var(--unit) * 10) !important;
}
.mr-11 {
  margin-right: calc(var(--unit) * 11) !important;
}
.mr-12 {
  margin-right: calc(var(--unit) * 12) !important;
}

.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: var(--unit) !important;
}
.pt-2 {
  padding-top: calc(var(--unit) * 2) !important;
}
.pt-3 {
  padding-top: calc(var(--unit) * 3) !important;
}
.pt-4 {
  padding-top: calc(var(--unit) * 4) !important;
}
.pt-5 {
  padding-top: calc(var(--unit) * 5) !important;
}
.pt-6 {
  padding-top: calc(var(--unit) * 6) !important;
}
.pt-7 {
  padding-top: calc(var(--unit) * 7) !important;
}
.pt-8 {
  padding-top: calc(var(--unit) * 8) !important;
}
.pt-9 {
  padding-top: calc(var(--unit) * 9) !important;
}
.pt-10 {
  padding-top: calc(var(--unit) * 10) !important;
}
.pt-11 {
  padding-top: calc(var(--unit) * 11) !important;
}
.pt-12 {
  padding-top: calc(var(--unit) * 12) !important;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: var(--unit) !important;
}
.pl-2 {
  padding-left: calc(var(--unit) * 2) !important;
}
.pl-3 {
  padding-left: calc(var(--unit) * 3) !important;
}
.pl-4 {
  padding-left: calc(var(--unit) * 4) !important;
}
.pl-5 {
  padding-left: calc(var(--unit) * 5) !important;
}
.pl-6 {
  padding-left: calc(var(--unit) * 6) !important;
}
.pl-7 {
  padding-left: calc(var(--unit) * 7) !important;
}
.pl-8 {
  padding-left: calc(var(--unit) * 8) !important;
}
.pl-9 {
  padding-left: calc(var(--unit) * 9) !important;
}
.pl-10 {
  padding-left: calc(var(--unit) * 10) !important;
}
.pl-11 {
  padding-left: calc(var(--unit) * 11) !important;
}
.pl-12 {
  padding-left: calc(var(--unit) * 12) !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: var(--unit) !important;
}
.pb-2 {
  padding-bottom: calc(var(--unit) * 2) !important;
}
.pb-3 {
  padding-bottom: calc(var(--unit) * 3) !important;
}
.pb-4 {
  padding-bottom: calc(var(--unit) * 4) !important;
}
.pb-5 {
  padding-bottom: calc(var(--unit) * 5) !important;
}
.pb-6 {
  padding-bottom: calc(var(--unit) * 6) !important;
}
.pb-7 {
  padding-bottom: calc(var(--unit) * 7) !important;
}
.pb-8 {
  padding-bottom: calc(var(--unit) * 8) !important;
}
.pb-9 {
  padding-bottom: calc(var(--unit) * 9) !important;
}
.pb-10 {
  padding-bottom: calc(var(--unit) * 10) !important;
}
.pb-11 {
  padding-bottom: calc(var(--unit) * 11) !important;
}
.pb-12 {
  padding-bottom: calc(var(--unit) * 12) !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: var(--unit) !important;
}
.pr-2 {
  padding-right: calc(var(--unit) * 2) !important;
}
.pr-3 {
  padding-right: calc(var(--unit) * 3) !important;
}
.pr-4 {
  padding-right: calc(var(--unit) * 4) !important;
}
.pr-5 {
  padding-right: calc(var(--unit) * 5) !important;
}
.pr-6 {
  padding-right: calc(var(--unit) * 6) !important;
}
.pr-7 {
  padding-right: calc(var(--unit) * 7) !important;
}
.pr-8 {
  padding-right: calc(var(--unit) * 8) !important;
}
.pr-9 {
  padding-right: calc(var(--unit) * 9) !important;
}
.pr-10 {
  padding-right: calc(var(--unit) * 10) !important;
}
.pr-11 {
  padding-right: calc(var(--unit) * 11) !important;
}
.pr-12 {
  padding-right: calc(var(--unit) * 12) !important;
}
